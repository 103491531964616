import firebase from './../../lib/firebase';

export default {
	
	/* Get Groups */
	
	getGroups: () => {
		
		return firebase.database().ref('contacts/groups');
	},
	
	/* Get Group */
	
	getGroup: (id) => {
		
		return firebase.database().ref('contacts/groups/'+id);
	},
	
	/* Create New Group */
	
	createGroup: (title, description) => {
		
		return firebase.database().ref('contacts/groups').push({
			title: title,
			description: description
		});
	},
	
	/* Save Group */
	
	saveGroup: (id, title, description) => {
		
		return firebase.database().ref('contacts/groups').child(id).update({
			title: title,
			description: description
		})
	},
	
	/* Delete Group */
	
	deleteGroup: (id) => {
		
		firebase.database().ref('contacts/entries').on('value', resp => {
			const contacts = resp.val();
			for (let key in contacts) {
				const { group } = contacts[key];
				if (group == id) {
					firebase.database().ref('contacts/entries').child(key).remove();
				}
			}
		})
		return firebase.database().ref('contacts/groups').child(id).remove();
	},
	
	/* Reorder Groups */
	
	reorderGroups: (order) => {
		
		order.map((group, index) => {
			firebase.database().ref('contacts/groups').child(group).update({
				order: index
			});
		})
	},
	
	/* Get Contacts */
	
	getContacts: () => {
		
		return firebase.database().ref('contacts/entries');
	},
	
	/* Get Contact */
	
	getContact: (id) => {
		
		return firebase.database().ref('contacts/entries/'+id);
	},
	
	/* Create New Contact */
	
	createContact: (name, group, details) => {
		
		return firebase.database().ref('contacts/entries').push({
			name: name,
			group: group,
			details
		});
	},
	
	/* Save Contact */
	
	saveContact: (id, name, group, details) => {
		
		return firebase.database().ref('contacts/entries').child(id).update({
			name: name,
			group: group,
			details
		})
	}, 
	
	/* Delete Contact */
	
	deleteContact: (id) => {
		
		return firebase.database().ref('contacts/entries').child(id).remove();
	},
	
	/* Reorder Contacts */
	
	reorderContacts: (order) => {
		
		order.map((contact, index) => {
			firebase.database().ref('contacts/entries').child(contact).update({
				order: index
			});
		})
	}
}