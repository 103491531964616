import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import pageActions from './../../actions/pages';
import assessmentActions from './../../actions/assessment';
import { emotions } from './../../consts';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class SelfAssessmentEntry extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: true, 
            id: null,
            emotion: '', 
            steps: []
		}
	}
	
	componentDidMount() {
		
        const { match } = this.props;
      
        if (match.params.id) { 
		
			assessmentActions.getEntry(match.params.id).on('value', resp => {

                let { emotion, steps } = {...resp.val()}
              
                if (!steps) {
                    steps = this.state.steps;
                }
                this.setState({
					id: match.params.id,
					emotion: emotion,
                    steps: steps
				})
			});
		}
        else {
          
            this.setState({
                steps: [{
                    content: '',
                    timer: 0,
                    links: []
                }]
            })
        }
      
	}
	
    save() {
		
        const { history } = this.props;
		const { loading, id, ...data } = this.state;
        
        const action = id ? 
			assessmentActions.saveEntry(id, data) : 
			assessmentActions.createEntry(data)
      
        action.then((resp) => {
			history.push('/self-assessment');
		})
	}
	
    delete() {
      
        const { id } = this.state;
      
        if (window.confirm("Are you sure you want to delete this entry?")) {
            
            assessmentActions.deleteEntry(id).then((resp) => {
				const { history } = this.props;
				history.push('/self-assessment');
			})
        }
    }
  
	cancel() {
		
		const { history } = this.props;
		
        history.push('/self-assessment');
	}
  
    addStep() {
        
        let { steps } = this.state;
        steps.push({
            content: '',
            timer: 0,
            links: []
        })
        
        this.setState({
            steps: steps
        })
    }
  
    updateStep(index, data) {
      
        let { steps } = this.state;
        steps[index].content = data.content;
        steps[index].timer = data.timer;
        steps[index].links = data.links;
        
        this.setState({
            steps: steps
        })
    }
  
    reorderSteps(steps, result) {
		
		if (!result.source || !result.destination) { return; }
		
        const { id } = this.state;
      
		const from = result.source.index;
		const to = result.destination.index;
		
		steps.splice(to, 0, steps.splice(from, 1)[0]); 
		
		assessmentActions.reorderSteps(id, steps);
	}
  
    deleteStep(index) {

        let { steps } = this.state;
        steps.splice(index, 1);
      
        this.setState({
            steps: steps
        })
    }
  
	render() {
		
        const { match } = this.props;
        const { id, emotion, steps } = this.state;
      
        return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } Entry</h1>
				</div>
                <div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field-group">
						<div className="form-field">
							<label className="input-label">Emotion</label>
							<select 
                                value={ emotion }
								className="select-box select-box-block"
								onChange={(event) => {
									this.setState({ emotion: event.target.value })
								}}>
								<option value={'none'}>Select Emotion</option>
								<option value={'none'} disabled>------</option>
                                { emotions.map((e, index) => {
                                    return (
                                        <option key={index} value={e}>{ e }</option>
                                    )
                                })}
							</select>
						</div>
					</div>
                            
                    { steps && <div style={{ margin: '2.5rem 0' }}>
                        <DragDropContext onDragEnd={(result) => { this.reorderSteps(steps, result) }}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                        { steps.map((e, index) => {
                                            return (
                                                <Draggable 
                                                    key={'key-' + index} 
                                                    draggableId={'id-' + index} 
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <SelfAssessmentEntryStep
                                                                key={index}
                                                                index={index}
                                                                open={!match.params.id || index == steps.length-1}
                                                                data={e}
                                                                onUpdate={(index, data) => this.updateStep(index, data)}
                                                                onDelete={(index) => this.deleteStep(index)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        }) }
                                        {provided.placeholder}
                                  </div>
                              )}
                          </Droppable>
                      </DragDropContext>
                    </div> }
                    <button 
                        className="button button-secondary"
                        onClick={() => this.addStep()}>Add Step</button>
                    <div className="button-bar">
                        <button 
                            className="button button-primary" 
                            onClick={() => { this.save() }}>Save</button>
                        <button 
                            className="button button-primary button-outline" 
                            onClick={() => { this.cancel() }}>Cancel</button>
                        { id && <button 
							className="button button-danger button-align-end" 
							onClick={() => { this.delete() }}>Delete</button> }
                    </div>
                </div>
			</div>
		);
      
		/*return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } Entry</h1>
				</div>
                <div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field-group">
						<div className="form-field">
							<label className="input-label">Emotion</label>
							<select 
                                value={ emotion }
								className="select-box select-box-block"
								onChange={(event) => {
									this.setState({ emotion: event.target.value })
								}}>
								<option value={'none'}>Select Emotion</option>
								<option value={'none'} disabled>------</option>
                                { emotions.map((e, index) => {
                                    return (
                                        <option key={index} value={e}>{ e }</option>
                                    )
                                })}
							</select>
						</div>
					</div>
                    { steps.map((e, index) => {
                        return (
                            <SelfAssessmentEntryStep
                                key={index}
                                index={index}
                                open={!match.params.id || index == steps.length-1}
                                data={e}
                                onUpdate={(index, data) => this.updateStep(index, data)}
                                onDelete={(index) => this.deleteStep(index)} />
                        )
                    }) }
                    <button 
                        className="button button-secondary"
                        onClick={() => this.addStep()}>Add Step</button>
                    <div className="button-bar">
                        <button 
                            className="button button-primary" 
                            onClick={() => { this.save() }}>Save</button>
                        <button 
                            className="button button-primary button-outline" 
                            onClick={() => { this.cancel() }}>Cancel</button>
                        { id && <button 
							className="button button-danger button-align-end" 
							onClick={() => { this.delete() }}>Delete</button> }
                    </div>
                </div>
			</div>
		);*/
	}
}

class SelfAssessmentEntryStep extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
            //open: true,
            open: false,
            pages: [],
            /**/
            content: '',
            timer: 0,
            links: []
		}
	}
	
	componentDidMount() {
		
        pageActions.getPages().on('value', resp => {
			
			const pages = resp.val();
			const pagesArray = Object.keys(pages).map((key) => { return { id: key, ...pages[key] } });
			
			this.setState({
				pages: pagesArray
			})
		});
      
        let { data, open } = this.props;

        this.setState({
            open: open
        })
      
        this.setContent(data);
	}
  
    componentDidUpdate(prevProps) {
      
        let { data } = this.props;
      
        if (data != prevProps.data) {
            
            this.setContent(data);
        }
    }
  
    setContent(data) {
        
        if (typeof data != 'object' || data == null) { return; }
      
        data = { content: '', timer: 0, links: [], ...data }

        this.setState({
            content: data.content, 
            timer: data.timer,
            links: data.links
        })
    }
  
    toggle() {
        
        this.setState({
            open: !this.state.open  
        })
    }
  
    delete() {
      
        const { onDelete, index } = this.props;
      
        if (window.confirm("Are you sure you want to delete this step?")) {
            onDelete(index)
        }
    }
  
    addPageLink(id) {
        
        let { links } = this.state;
        links.push(id);
        
        this.setState({
            links: links
        }, () => {
            this.updateModel()
        })
    }
  
    removePageLink(id) {
      
        let { links } = this.state;
        links = links.filter(l => l !== id);
      
        this.setState({
            links: links
        }, () => {
            this.updateModel()
        })
    }
  
    updateModel() {
        
        const { onUpdate, index } = this.props;
        const { content, timer, links } = this.state;
      
        const data = {
            content, timer, links
        }
      
        onUpdate(index, data);
    }
  
    _renderPagesList() {
		
		const allPages = this.state.pages;
		
		let level = 0;
		let pagesArray = [];
        let root = null;
		
		const loop = (pages, parent) => {
            
			pages.filter(p => p.parent == parent).map((page) => {
				
                root = !parent ? page.title : root;
                
				const { id, title, children = null } = page;
			
				if (id == this.state.id) {
					return false;
				}
				
				level = 0;
				
				/* Get 'level' of parent page and add 1 */
				if (page.parent) {
					for (let i = 0; i < pagesArray.length; i++) {
						if (pagesArray[i].id == page.parent) {
							level = pagesArray[i].level + 1;
						}
					}
				}
				
				const childrenArray = children ? Object.keys(children) : [];
                
				let childPages = allPages.filter(p => childrenArray.indexOf(p.id) > -1);
					childPages.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
				
				pagesArray.push({ level, id, title, children })
				
				if (childPages.length > 0) {
					loop(childPages, id)
				}
			})
		}
		
		const { pages } = this.state;

		loop(pages, null)
		
		return (
			pagesArray.map((page) => {
				return (
					<option key={page.id} value={page.id}>{ ('\u00A0 \u00A0 \u00A0').repeat(page.level) }{ page.title }</option>
				)
			})
		)
	}
  
	render() {
		
        const { index } = this.props;
        const { open, content, timer, links, pages } = this.state;

		return (
			<div className={"self-assessment-step " + (!open ? "self-assessment-step-closed" : "")}>
                <div className="title">
                    <div className="drag-me"></div>
                    { !content && <h2>Step { index+1 }</h2> }
                    { content && <h2>{ content.split(' ').slice(0, 6).join(' ') }...</h2> }
                    <button
                        className={"button button-toggle " + (open ? "button-toggle-open" : "")}
                        onClick={() => this.toggle()}></button>
                </div>
                { open && <div>
                    <div className="form-field" style={{ marginBottom: 0 }}>
                        <label className="input-label">Content</label>
                        <textarea 
                            value={ content }
                            rows={4}
                            name="title" 
                            placeholder="Enter Title"
                            className="text-input text-input-block"
                            onChange={(event) => {
                                this.setState({
                                    content: event.target.value
                                }, () => {
                                    this.updateModel()
                                })
                            }}></textarea>
                    </div>
                    <div className="form-field-group">
                        <div className="form-field form-field">
                            <label className="input-label">Timer</label>
                            <label className="input-label input-label-note">Set to 0 for no timer.</label>
                            <input 
                                value={ timer }
                                type="number"
                                min={0}
                                max={99}
                                className="text-input number-input"
                                onChange={(event) => {
                                    this.setState({
                                        timer: event.target.value
                                    }, () => {
                                        this.updateModel()
                                    })
                                }} /> seconds
                        </div>
                        <div className="form-field form-field" style={{ flex: 2 }}>
                            <label className="input-label">Links (optional)</label>
                            <label className="input-label input-label-note">Add links to other pages.</label>
                            <select
                                value={'none'}
                                className="select-box select-box-block"
                                onChange={(event) => {
                                    this.addPageLink(event.target.value);
                                }}>
                                <option value={'none'}>Select Page</option>
                                <option value={'none'} disabled>------</option>
                                { this._renderPagesList() }
                            </select>
                            <ul className="page-links-list">
                                { links.map((e, index) => {
                                  
                                    const page = pages.find(p => p.id === e);
                                 
                                    if (!page) { return }
                                 
                                    return (
                                        <li key={index} className="page-links-list-item">
                                            <div className="page-links-list-item-inner">
                                                <span>{ page.title }</span>
                                                <button 
                                                    className="btn-remove-link"
                                                    onClick={() => this.removePageLink(page.id)}></button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    { index > 0 && <button 
                        className="button button-danger"
                        onClick={() => this.delete()}>Delete Step</button> }
                </div> }
            </div>
		);
	}
}
