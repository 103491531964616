import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import firebase from './lib/firebase';
import './App.scss';
/* Screens */
import { Login } from './screens/login';
import { ForgotPassword } from './screens/login/forgot';
import { Home } from './screens/home';
import { Pages } from './screens/pages';
import { PageEntry } from './screens/pages/entry';
import { Contacts } from './screens/contacts';
import { ContactEntry } from './screens/contacts/entry-contact';
import { ContactsGroup } from './screens/contacts/group';
import { ContactGroupEntry } from './screens/contacts/entry-group';
import { Users } from './screens/users';
import { UsersEntry } from './screens/users/entry';
import { SelfAssessment } from './screens/self-assessment';
import { SelfAssessmentEntry } from './screens/self-assessment/entry';
/* Actions */
import authActions from './actions/auth';

class App extends Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			user: null
		}
	}
	
	componentWillMount() {
		firebase.auth().onAuthStateChanged((user) => {
			this.setState({
				user: user
			})
		});
	}
	
	logout() {
      
        const { history } = this.props;
      
		if (window.confirm("Are you sure you want to sign out?")) {
			authActions.logoutUser();
            window.location.href = "/";
		}
	}
	
	render() {
		
		const currentUser = authActions.getCurrentUser();
		
		if (!this.state.user) {
		
			return (
				<Router>
                    <div className="main">
                        <Route path={'/forgot'} exact component={ ForgotPassword } />
                        <Route path={'/'} exact component={ Login } />
                    </div>
				</Router>
			);
			
		} else {
			
			return (
				<Router>
					<div className="wrapper">
						<div className="sidebar">
							<div style={{ flex: 1 }}>
								<div className="title">
                                    <img src={require('./assets/logo-icon.png')} className="logo" />
                                    <h1>Changing Lives<span>Admin Panel</span></h1>
								</div>
								<ul className="nav-list">
									<li className="nav-list-item">
										<Link to="/pages">Pages</Link>
									</li>
                                    <li className="nav-list-item">
										<Link to="/self-assessment">Self Assessment</Link>
									</li>
									<li className="nav-list-item">
										<Link to="/contacts">Contacts</Link>
									</li>
									<li className="nav-list-item">
										<Link to="/users">Users</Link>
									</li>
									<li className="nav-list-item nav-list-seperate">
										<a onClick={() => { this.logout() }}>Sign Out</a>
									</li>
								</ul>
							</div>
							<p className="current-user">Currently logged in as: { currentUser.email }</p>
						</div>
						<div className="main">
							<Route path={'/'} exact component={ Pages } />
							<Route path={'/pages'} exact component={ Pages } />
							<Route path={'/pages/new'} exact component={ PageEntry } />
							<Route path={'/pages/new/:parent'} exact component={ PageEntry } />
							<Route path={'/pages/entry/:id'} exact component={ Pages } />
							<Route path={'/pages/entry/:id/edit'} exact component={ PageEntry } />
							<Route path={'/contacts'} exact component={ Contacts } />
							<Route path={'/contacts/group/new'} exact component={ ContactGroupEntry } />
							<Route path={'/contacts/group/:id/edit'} exact component={ ContactGroupEntry } />
							<Route path={'/contacts/group/:id/view'} exact component={ ContactsGroup } />
							<Route path={'/contacts/group/:group/new'} exact component={ ContactEntry } />
							<Route path={'/contacts/entry/:entry/edit'} exact component={ ContactEntry } />
							<Route path={'/users'} exact component={ Users } />
							<Route path={'/users/admin/:id/edit'} exact component={ UsersEntry } />
							<Route path={'/users/admin/new'} exact component={ UsersEntry } />
                            <Route path={'/self-assessment'} exact component={ SelfAssessment } />
                            <Route path={'/self-assessment/new'} exact component={ SelfAssessmentEntry } />
                            <Route path={'/self-assessment/entry/:id/edit'} exact component={ SelfAssessmentEntry } />
						</div>
					</div>
				</Router>
			)
		}
	}
}

export default App;
