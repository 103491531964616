import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import contactActions from './../../actions/contacts';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class Contacts extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			groups: null,
			loading: true
		}
	}
	
	componentDidMount() {
			
		this.getGroups();
	}
	
	getGroups() {
		
		contactActions.getGroups().on('value', resp => {
			
			const groups = resp.val();
			
			if (groups == null) { return; }
			
			let groupsArray = Object.keys(groups).map((key) => { return { id: key, ...groups[key] } });
				groupsArray.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
			
			this.setState({
				groups: groupsArray,
				loading: false
			})
		});
	}
	
	reorderList(result) {
		
		const from = result.source.index;
		const to = result.destination.index;
		
		let { groups } = this.state;
		
		groups.splice(from, 0, groups.splice(to, 1)[0]); 
		
		const order = groups.map(p => p.id);
		
		this.setState({
			groups: groups
		})
		
		contactActions.reorderGroups(order, null);
	}
	
	_renderSortableList() {
		
		const { groups } = this.state;
		
		return (
			<div className="contents-list">
				<DragDropContext onDragEnd={(result) => { this.reorderList(result) }}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef}>
								{ groups.map((group, index) => {
								 
								 	return (
										<Draggable 
											key={group.id} 
											className="contents-list-item" 
											draggableId={group.id} 
											index={index}>
											{(provided, snapshot) => (
												<div
													className="contents-list-item" 
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}>
													<div className="list-item-inner">
														<div className="drag-me"></div>
														<Link to={'/contacts/group/' + group.id + '/view'}>{ group.title }</Link>
														<Link to={'/contacts/group/' + group.id + '/edit'}>(Edit)</Link>
													</div>
												</div>
											)}
										</Draggable>
									)
								}) }
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		)
	}
	
	render() {
		
		const { groups, loading } = this.state;
		
		return (
			<div>
				<div className="title">
					<h1>Contacts</h1>
				</div>
				{ loading && <img 
					src={require('./../../assets/loading.svg')} 
					className="loading" /> }
				{ groups && <div>
					{ this._renderSortableList() }
				</div> }
				<Link to="/contacts/group/new" className="button button-primary">Add New Group</Link>
			</div>
		);
	}
}
