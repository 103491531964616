import firebase from './../../lib/firebase';

export default {
	
	/* Current User */
	
	getCurrentUser: () => {
		
		return firebase.auth().currentUser
	}, 
	
	/* Login With Email */
	
	loginWithEmail: (email, password) => {
		
		/* Get user type from database */
		const userAccess = () => new Promise((resolve, reject) => {
			firebase.database().ref('users').on('value', resp => {
				const users = {...resp.val()}
				for (let key in users) {
					if (users[key].email == email && users[key].type != 'admin') {
						reject("You do not have permission to access this site.")
					}
				}
				resolve()
			})
		})
		return userAccess().then(resp => {
			return firebase.auth().signInWithEmailAndPassword(email, password);
		}, err => {
			return new Promise((resolve, reject) => { return reject({
				message: err
			}) })
		})
	}, 
	
	/* Logout */
	
	logoutUser: () => {
        return firebase.auth().signOut();
    },
	
	/* Update Password */

    updatePassword: (currentPassword, newPassword) => {
		const user = firebase.auth().currentUser;
		return firebase.auth().signInWithEmailAndPassword(user.email, currentPassword).then(() => {
			return user.updatePassword(newPassword);
		});
    },
	
	/* Recover Password */

    recoverPassword: (email) => {
        return firebase.auth().sendPasswordResetEmail(email);
    }
	
}