/* Protect pages / content - These cannot be deleted as they're referenced in the app */
export const protectedPages = [
	"-LYbiwxBLgYo-HeS_tpf", /* Terms and Conditions */
	"-LYCxQm0g2pzLxbTJxgM", /* PBNI Offices */
    "-L_g9b-gxbbpTiaclyLP", /* Community Tracker */
    "-L_g9vXeWfECwDP07ebO" /* Self Assessment */
]

/* Pages that have no content */
export const noContentPages = [
    "-L_g9b-gxbbpTiaclyLP", /* Community Tracker */
    "-L_g9vXeWfECwDP07ebO" /* Self Assessment */
]

/* List of emotions */
export const emotions = [
    "Angry", 
    "Annoyed", 
    "Anxious", 
    "Apprehensive", 
    "Bored", 
    "Cheerful", 
    "Confident", 
    "Depressed", 
    "Enthusiastic", 
    "Excited", 
    "Fearful",  
    "Fine",  
    "Frightened",  
    "Good",  
    "Happy",  
    "Inspired",  
    "Love",  
    "Miserable",  
    "Nervous",  
    "Playful",  
    "Sad",  
    "Safe",  
    "Sick",  
    "Stressed",  
    "Surprised",  
    "Unhappy",  
    "Unsure",  
    "Upset",  
    "Worried"
]