import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authActions from './../../actions/auth';
import userActions from './../../actions/users';

export class Users extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			users: null,
			tab: 'admin',
			search: '',
			loading: true
		}
	}
	
	componentDidMount() {
		
		/* Get Users */
		
		userActions.getUsers().on('value', resp => {
			
			const users = resp.val();
			
			if (users == null) { return; }
			
			let usersArray = Object.keys(users).map((key) => { return { id: key, ...users[key] } });
			
			this.setState({
				users: usersArray,
				loading: false
			})
			
		});
		
	}
	
	render() {
		
		const { users, tab, search, loading } = this.state;
		const currentUserId = authActions.getCurrentUser().uid;
		
		let adminUsers = users ? users.filter(u => u.type == 'admin') : [];
		
        adminUsers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      
		const filteredAdminUsers = adminUsers.filter(u => !u.name || u.name.toLowerCase().includes(search.toLowerCase()));
		
		return (
			<div>
				<div className="title">
					<h1>Users</h1>
				</div>
				<div className="form">
					<div className="form-field">
						<input 
							type="text" 
							name="title" 
							placeholder="Search Users"
							value={search} 
							className="text-input text-input-block search-input"
							onChange={(event) => {
								this.setState({ search: event.target.value })
							}} />
					</div>
				</div>
				{ loading && <img 
					src={require('./../../assets/loading.svg')} 
					className="loading" /> }
				{ (adminUsers && tab == 'admin') && <div>
					<ul className="contents-list">
						{ filteredAdminUsers.map((user) => {
							const goTo = "/users/admin/" + user.id + "/edit";
							return (
								<li className="contents-list-item">
									<div className="list-item-inner">
										<p><Link to={goTo}>{ user.name ? user.name : user.email } { user.id == currentUserId && "(you)"}</Link></p>
									</div>
								</li>
							)
						})}
					</ul>
					<Link to="/users/admin/new" className="button button-primary">Add New User</Link>
				</div> }
			</div>
		);
	}
}
