import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import pageActions from './../../actions/pages';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class Pages extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			pages: null,
			parentPage: null,
			tab: 'primary',
			loading: true
		}
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		let parent = typeof match.params.id == 'undefined' ? null : match.params.id;
		
		this.getPages(parent);
	}
	
	componentDidUpdate(prevProps) {
		
		const { match } = this.props;
		
		if (prevProps.match.params.id != match.params.id) {
			
			let parent = typeof match.params.id == 'undefined' ? null : match.params.id;
		
			this.getPages(parent);
		}
	}
	
	getPages(parent) {
		
		pageActions.getPages().on('value', resp => {
			
			const pages = resp.val();
			
			if (pages == null) { return; }
			
			let parentPage = parent ? pages[parent] : null;
				
			let pagesArray = Object.keys(pages).map((key) => { return { id: key, ...pages[key] } });
				pagesArray = pagesArray.filter(p => p.parent == parent);
				pagesArray.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
			
			this.setState({
				pages: pagesArray,
				parentPage: parentPage,
				loading: false
			})
		});
	}
	
	reorderList(pages, result) {
		
		if (!result.source || !result.destination) { return; }
		
		const from = result.source.index;
		const to = result.destination.index;
		
		pages.splice(to, 0, pages.splice(from, 1)[0]); 
		
		const order = pages.map(p => p.id);
		
		pageActions.reorderPages(order, null);
	}
	
	_renderPagesList(pages) {
		
		const allPages = this.state.pages;
		
		return (
			<div className="contents-list">
				<DragDropContext onDragEnd={(result) => { this.reorderList(pages, result) }}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef}>
								{ pages.map((page, index) => {
								 
								 	const children = page.children ? Object.keys(page.children) : [];
				 					const childPages = allPages.filter(p => children.indexOf(p.id) > -1);
								 
								 	return (
										<Draggable 
											key={page.id} 
											className="contents-list-item" 
											draggableId={page.id} 
											index={index}>
											{(provided, snapshot) => (
												<div
													className="contents-list-item" 
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}>
													<div className="list-item-inner">
														<div className="drag-me"></div>
														<Link to={'/pages/entry/' + page.id + '/edit'}>{ page.title }</Link>
														{ page.children && <Link to={'/pages/entry/' + page.id}>(Sub Pages)</Link> }
													</div>
												</div>
											)}
										</Draggable>
									)
								}) }
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		)
	}
	
	render() {
		
		const { pages, parentPage, tab, loading } = this.state;
		const { match } = this.props;
		
		const newPageLink = "/pages/new/" + (typeof match.params.id == 'undefined' ? '' : match.params.id);
		
		return (
			<div>
				<div className="title">
					<h1>
						Pages
						{ parentPage && <span className="seperator" /> }
						{ parentPage && parentPage.title }
					</h1>
					{ !parentPage && <div className="tabs">
						<div 
							className={`tab ${tab == "primary" ? "tab-active" : ''}`}
							onClick={() => { this.setState({ tab: 'primary' }) }}>
							Primary</div>
						<div 
							className={`tab ${tab == "secondary" ? "tab-active" : ''}`}
							onClick={() => { this.setState({ tab: 'secondary' }) }}>
							Secondary</div>
						{/*` This comment serves not purpose */}
					</div> }
				</div>
				{ loading && <img 
					src={require('./../../assets/loading.svg')} 
					className="loading" /> }
				{ (pages && tab == 'primary') && <div>
					{ this._renderPagesList(pages.filter(p => p.style != 'secondary')) }
				</div> }
				{ (!parentPage && pages && tab == 'secondary') && <div>
					{ this._renderPagesList(pages.filter(p => p.style == 'secondary')) }
				</div> }
				<Link to={newPageLink} className="button button-primary">Add New Page</Link>
			</div>
		);
	}
}
