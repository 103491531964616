import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import contactActions from './../../actions/contacts';

export class ContactEntry extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null, 
			name: '',
			group: null,
			groups: null,
			details: {}
		}
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		this.setState({
			group: typeof match.params.group != 'undefined' ? match.params.group : null
		}, () => {
			
			if (match.params.entry) {
				this.getContact(match.params.entry);
			}
			this.getGroups();
		})
	}
	
	getGroups() {
		
		contactActions.getGroups().on('value', resp => {
			
			const groups = resp.val();
			const groupsArray = Object.keys(groups).map((key) => { return { id: key, ...groups[key] } });
			
			this.setState({
				groups: groupsArray
			})
		});
	}
	
	getContact(id) {
		
		contactActions.getContact(id).on('value', resp => {
			
			const contact = resp.val();
			const { name, group, details = {} } = contact;
			
			this.setState({ id, name, group, details })
		})
	}
	
	save() {
		
		const { history } = this.props;
		const { id, name, group, details } = this.state;
		
		if (id) {
			
			contactActions.saveContact(id, name, group, details).then((resp) => {
				history.push('/contacts/group/'+group+'/view');
			})
			
		} else {
		
			contactActions.createContact(name, group, details).then((resp) => {
				history.push('/contacts/group/'+group+'/view');
			})
		}
	}
	
	cancel() {
		
		const { history } = this.props;
		const { group } = this.state;
		
		history.push('/contacts/group/'+group+'/view');
	}
	
	delete() {
		
		const { id, group } = this.state;
		
		if (window.confirm("Are you sure you want to delete this contact?")) {
			contactActions.deleteContact(id).then((resp) => {
				const { history } = this.props;
				history.push('/contacts/group/'+group);
			})
		}
	}
	
	updateContactDetails(key, value) {
		
		let { details } = this.state;
		details[key] = value;
		
		this.setState({
			details: details
		})
	}
	
	render() {
		
		const { id, name, group, details, groups } = this.state;
		
		return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } Contact</h1>
				</div>
				<div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field-group">
						<div className="form-field">
							<label className="input-label">Contact Name</label>
							<input 
								type="text" 
								name="name" 
								placeholder="Enter Name"
								value={name} 
								className="text-input text-input-block"
								onChange={(event) => {
									this.setState({ name: event.target.value })
								}} />
						</div>
						<div className="form-field">
							<label className="input-label">Group</label>
							<select
								value={ group }
								className="select-box select-box-block"
								onChange={(event) => {
									this.setState({ group: event.target.value })
								}}>
								<option disabled>Select Group</option>
								{ groups && groups.map((grp) => {
									return (
										<option key={grp.id} value={grp.id}>{ grp.title }</option>
									)
								}) }
							</select>
						</div>
					</div>
					
					<div className="form-field">
						<label className="input-label">Description / Notes</label>
						<input 
							type="text" 
							name="name" 
							placeholder="Enter Description"
							value={details.description} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('description', event.target.value)
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Address</label>
						<input 
							type="text" 
							name="name" 
							placeholder="Enter Address"
							value={details.address} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('address', event.target.value)
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Telephone</label>
						<input 
							type="tel" 
							name="name" 
							placeholder="Enter Telephone No."
							value={details.telephone} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('telephone', event.target.value)
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Email Address</label>
						<input 
							type="email" 
							name="name" 
							placeholder="Enter Email Address"
							value={details.email} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('email', event.target.value)
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Fax</label>
						<input 
							type="text" 
							name="name" 
							placeholder="Enter Fax No."
							value={details.fax} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('fax', event.target.value)
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Website</label>
						<input 
							type="url" 
							name="name" 
							placeholder="Enter Website URL"
							value={details.web} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.updateContactDetails('web', event.target.value)
							}} />
					</div>
							
					<div className="button-bar">
						<button 
							className="button button-primary" 
							onClick={() => { this.save() }}>Save</button>
						<button 
							className="button button-primary button-outline" 
							onClick={() => { this.cancel() }}>Cancel</button>
						{ id && <button 
							className="button button-danger button-align-end" 
							onClick={() => { this.delete() }}>Delete</button> }
					</div>
							
				</div>
			</div>
		);
	}
}
