import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authActions from './../../actions/auth';

export class ForgotPassword extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			email: '',
            loading: false
		}
	}
	
	recoverPassword(event) {
		event.preventDefault();
		const { email } = this.state;
        const { history } = this.props;
      
        this.setState({
            loading: true
        }, () => {
            authActions.recoverPassword(email).then((res) => {
                // SUCCESS
                this.setState({
                    loading: false
                });
                alert("A reset link has been sent to your email.");
                history.push('/');
            }, error => {
                 this.setState({
                    loading: false
                })
                alert(error.message);
            });
        });
	}
	
	render() {
      
        const { loading } = this.state;
      
		return (
			<div className="login">
				<form className="form" onSubmit={(event) => { this.recoverPassword(event) }}>
                    <div className="title">
                        <img src={require('./../../assets/logo-icon.png')} className="logo" />
                        <h1>Recover Password<span>Admin Panel</span></h1>
                    </div>
					<div className="form-field">
                        <label className="input-label">Email Address</label>
						<input 
							type="email" 
							name="email" 
							placeholder="Enter Email Address" 
							value={this.state.email} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ email: event.target.value })
							}}
							required />
					</div>
					
                    <div className="button-bar">
                        <input 
                            type="submit" 
                            value="Reset Password"
                            className="button button-primary" />
                        { loading && <img 
                            src={require('./../../assets/loading.svg')} 
                            className="loading" /> }
                        <Link 
                            to={"/"} 
                            className="button button-align-end">Cancel</Link>
                    </div>
				</form>
			</div>
		);
	}
}
