import firebase from 'firebase';

export const config = {
    apiKey: "AIzaSyAmN0J7LyUR6M3J1_piwjrZx-pK5ZuhUl4",
    authDomain: "changing-lives.firebaseapp.com",
    databaseURL: "https://changing-lives.firebaseio.com",
    projectId: "changing-lives",
    storageBucket: "changing-lives.appspot.com",
    messagingSenderId: "673549742973"
};

firebase.initializeApp(config);

export default firebase;
