import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authActions from './../../actions/auth';
import userActions from './../../actions/users';

export class UsersEntry extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			name: null,
			email: null,
			password: null,
			newPassword: null
		}
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		if (!match.params.id) { return; }
		
		userActions.getUser(match.params.id).on('value', resp => {
			
			const { name, email } = {...resp.val()}
		
			this.setState({
				id: match.params.id,
				name: name,
				email: email
			})
			
		});
		
	}
	
	save() {
		
		const { history } = this.props;
		const { id, name, email, password, newPassword } = this.state;
		
		const action = id ? 
			userActions.saveUser(id, name, email, password, newPassword) : 
			userActions.createUser(name, email, password)
		
		action.then((resp) => {
			history.push('/users');
		}, error => {
			alert(error.message);
		});
		
	}
	
	cancel() {
		
		const { history } = this.props;
		history.push('/users');
	}
	
	sendReset() {
		
		const { email } = this.state;
		
		if (window.confirm("Send a password reset email to " + email + "?")) {
			userActions.sendReset(email).then((resp) => {
				const { history } = this.props;
				alert("A reset password email has been sent to " + email);
				history.push('/users');
			})
		}
	}
	
	revokeAdmin() {
		
		const { id } = this.state;
		
		if (window.confirm("Do you want to revoke admin access for this user?")) {
			userActions.revokeAdmin(id).then((resp) => {
				const { history } = this.props;
				history.push('/users');
			})
		}
	}
	
	render() {
		
		const { id, name, email } = this.state;
		
		const currentUserId = authActions.getCurrentUser().uid;
		
		return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } User </h1>
				</div>
			
				<div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field">
						<label className="input-label">Name</label>
						<input 
							disabled={id && currentUserId != id}
							type="text" 
							name="title" 
							placeholder="Enter Name"
							value={name} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ name: event.target.value })
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Email</label>
						<input 
							disabled={id}
							type="email" 
							name="email" 
							placeholder="Enter Email"
							value={email} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ email: event.target.value })
							}} />
					</div>
							
					{ !id && <div className="form-field">
						<label className="input-label">Password</label>
						<input 
							type="password" 
							name="password" 
							placeholder="Enter Password"
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ password: event.target.value })
							}} />
					</div> }
							
					{ currentUserId == id && <div className="change-password-fields">
						<div className="form-field">
							<label className="input-label">Current Password</label>
							<input 
								type="password" 
								name="currentpassword" 
								placeholder="Enter Current Password"
								className="text-input text-input-block"
								onChange={(event) => {
									this.setState({ password: event.target.value })
								}} />
						</div>
						<div className="form-field">
							<label className="input-label">New Password</label>
							<input 
								type="password" 
								name="newpassword" 
								placeholder="Enter New Password"
								className="text-input text-input-block"
								onChange={(event) => {
									this.setState({ newPassword: event.target.value })
								}} />
						</div>
					</div>}
							
					<div className="button-bar">
						{ (currentUserId == id || !id) && <button 
							className="button button-primary" 
							onClick={() => { this.save() }}>Save</button> }
						<button 
							className="button button-primary button-outline" 
							onClick={() => { this.cancel() }}>Cancel</button>
						{ (id && currentUserId != id) && <button 
							className="button button-secondary button-align-end" 
							onClick={() => { this.sendReset() }}>Send Password Reset Email</button> }
						{ (id && currentUserId != id) && <button 
							className="button button-danger" 
							onClick={() => { this.revokeAdmin() }}>Revoke Admin Access</button> }
					</div>
				</div>
			</div>
		);
	}
}
