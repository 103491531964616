import firebase from './../../lib/firebase';

export default {
	
    /* Get Entries */
	
    getEntries: () => {
      
        return firebase.database().ref('selfassessment');
    },
  
    /* Get Entry */
	
	getEntry: (id) => {
		
		return firebase.database().ref('selfassessment/'+id);
	},
  
	/* Create Entry */
	
    createEntry: (data) => {
      
        return firebase.database().ref('selfassessment').push(data);
    },
  
	/* Save Entry */
	
    saveEntry: (id, data) => {
      
        return firebase.database().ref('selfassessment').child(id).update(data);
    },
  
	/* Delete Entry */
	
    deleteEntry: (id) => {
      
        return firebase.database().ref('selfassessment').child(id).remove();
    },
	
	/* Reorder Entries */
	
	reorderEntries: (order) => {
		
		order.map((entry, index) => {
			firebase.database().ref('selfassessment').child(entry).update({
				order: index
			});
		})
	},
	
	/* Reorder Steps */
	
	reorderSteps: (id, data) => {
		
		firebase.database().ref('selfassessment').child(id).child('steps').update(data);
	}
	
}



