import firebase, { config } from './../../lib/firebase';
import authActions from './../auth';

export default {
	
	/* Get Users */
	
	getUsers: () => {
		
		return firebase.database().ref('users');
	},
	
	/* Get User */
	
	getUser: (id) => {
		
		return firebase.database().ref('users/'+id);
	},
	
	/* Create New User */
	
	createUser: (name, email, password) => {
		
		/* Create a second instance of the firebase app to prevent the user being logged in as the new user they're creating. */
		var secondaryApp;
		
		if (firebase.apps.length > 1) {
			for (let i = 0; i < firebase.apps.length; i++) {
				if (firebase.apps[i].name == "secondary") {
					secondaryApp = firebase.apps[i];
				}
			}
		} else {
			secondaryApp = firebase.initializeApp(config, "secondary");	
		}
		
		return secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(resp => {
			firebase.database().ref('users').child(resp.user.uid).set({
				name: name, 
				email: email,
				type: 'admin'
			});
			secondaryApp.auth().signOut();
		});
	},
	
	/* Save User */
	
	saveUser: (id, name, email, password, newPassword) => {
		
		return firebase.database().ref('users').child(id).update({
			name: name
		}).then((resp) => {
			
			if (password && password != "") { 
				return authActions.updatePassword(password, newPassword);
			} else {
				return;	
			}
		});
		
	},
	
	/* Send Reset Email */
	
	sendReset: (email) => {
		
		return authActions.recoverPassword(email);
	},
	
	/* Revoke Admin Access */
	
	revokeAdmin: (id) => {
		
		return firebase.database().ref('users').child(id).update({
			type: 'service'
		});
	},
	
}

