import React, { Component } from 'react';
import authActions from './../../actions/auth';

export class Home extends Component {
	
	constructor(props) {
		super(props);
	}
	
	logout() {
		if (window.confirm("Are you sure you want to sign out?")) {
			authActions.logoutUser();
		}
	}
	
	render() {
		return (
			<div>
				<div className="title">
					<h1>Home</h1>
				</div>
				<button 
					className="button button-primary"
					onClick={() => { this.logout() }}>Sign Out</button>
			</div>
		);
	}
}
