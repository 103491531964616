import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import contactActions from './../../actions/contacts';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class ContactsGroup extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			group: null,
			contacts: null,
			loading: true
		}
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		this.getGroup(match.params.id);
		this.getGroupContacts(match.params.id);
	}
	
	getGroup(id) {
		
		contactActions.getGroup(id).on('value', resp => {
			
			const group = resp.val();
			
			this.setState({
				id: id,
				group: group
			})
		});
	}
	
	getGroupContacts(groupId) {
		
		contactActions.getContacts().on('value', resp => {
			
			const contacts = resp.val();
			
			if (contacts == null) { return; }
			
			let contactsArray = Object.keys(contacts).map((key) => { return { id: key, ...contacts[key] } });
				contactsArray = contactsArray.filter(c => c.group == groupId);
				contactsArray.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
			
			this.setState({
				contacts: contactsArray,
				loading: false
			})
		});
	}
	
	reorderList(result) {
		
		const from = result.source.index;
		const to = result.destination.index;
		
		let { contacts } = this.state;
		
		contacts.splice(from, 0, contacts.splice(to, 1)[0]); 
		
		const order = contacts.map(p => p.id);
		
		this.setState({
			contacts: contacts
		})
		
		contactActions.reorderContacts(order, null);
	}
	
	_renderSortableList() {
		
		const { contacts } = this.state;
		
		return (
			<div className="contents-list">
				<DragDropContext onDragEnd={(result) => { this.reorderList(result) }}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef}>
								{ contacts.map((contact, index) => {
								 
								 	return (
										<Draggable 
											key={contact.id} 
											className="contents-list-item" 
											draggableId={contact.id} 
											index={index}>
											{(provided, snapshot) => (
												<div
													className="contents-list-item" 
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}>
													<div className="list-item-inner">
														<div className="drag-me"></div>
														<Link to={'/contacts/entry/' + contact.id + '/edit'}>{ contact.name }</Link>
													</div>
												</div>
											)}
										</Draggable>
									)
								}) }
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		)
	}
	
	render() {
		
		const { id, group, contacts, loading } = this.state;
		
		return (
			<div>
				<div className="title">
					<h1>
						Contacts
						<span className="seperator" />
						{ group && group.title }
					</h1>
				</div>
				{ loading && <img 
					src={require('./../../assets/loading.svg')} 
					className="loading" /> }
				{ contacts && <div>
					{ this._renderSortableList() }
				</div> }
				{ id && <Link to={'/contacts/group/' + id + '/new'} className="button button-primary">Add New Contact</Link> }
			</div>
		);
	}
}
