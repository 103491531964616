import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import assessmentActions from './../../actions/assessment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class SelfAssessment extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            entries: null
		}
	}
	
	componentDidMount() {
      
		this.getEntries();
	}
  
    getEntries() {
		
		assessmentActions.getEntries().on('value', resp => {
			
			const entries = resp.val();
			
			if (entries == null) {
              
                this.setState({
                    loading: false
                })
                return; 
            }
			
            let entriesArray = Object.keys(entries).map((key) => { return { id: key, ...entries[key] } });
                entriesArray.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
          
			this.setState({
				entries: entriesArray,
				loading: false
			})
		});
	}
  
    reorderList(entries, result) {
		
		if (!result.source || !result.destination) { return; }
		
		const from = result.source.index;
		const to = result.destination.index;
		
		entries.splice(to, 0, entries.splice(from, 1)[0]); 
		
		const order = entries.map(p => p.id);
		
		assessmentActions.reorderEntries(order);
	}
	
	render() {
		
        const { loading, entries } = this.state;
      
		return (
			<div>
				<div className="title">
					<h1>Self Assessment</h1>
				</div>
                { loading && <img 
					src={require('./../../assets/loading.svg')} 
					className="loading" /> }
				{ entries && <div className="contents-list">
                    <DragDropContext onDragEnd={(result) => { this.reorderList(entries, result) }}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                    { entries.map((entry, index) => {
                                        return (
                                            <Draggable 
                                                key={entry.id} 
                                                className="contents-list-item" 
                                                draggableId={entry.id} 
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="contents-list-item" 
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <div className="list-item-inner">
                                                            <div className="drag-me"></div>
                                                            <Link to={'/self-assessment/entry/' + entry.id + '/edit'}>{ entry.emotion }</Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    }) }
                                    {provided.placeholder}
                              </div>
                          )}
                      </Droppable>
                  </DragDropContext>
				</div> }
                <Link to={'/self-assessment/new'} className="button button-primary">Add New Entry</Link>
			</div>
		);
	}
}
