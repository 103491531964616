import firebase from './../../lib/firebase';

export default {
	
	/* Get Pages */
	
	getPages: () => {
		
		return firebase.database().ref('pages');
	},
	
	/* Get Page */
	
	getPage: (id) => {
		
		return firebase.database().ref('pages/'+id);
	},
	
	/* Create New Page */
	
	createPage: (title, parent, style, body) => {
		
		if (parent == 'none') { parent = null; style = null; }
		
		return firebase.database().ref('pages').push({
			title: title,
			parent: parent,
			style: style,
			body: body
		}).then(({ key }) => {
			
			if (!parent) { return; }
			
			return firebase.database().ref('pages/'+parent).child('children').child(key).set(true);
		})
	},
	
	/* Save Page */
	
	savePage: (id, title, parent, style, body) => {
		
		if (parent == 'none') { parent = null; style = null; }
		
		/* Check current state of page */
		firebase.database().ref('pages/'+id).on('value', resp => {
			const page = {...resp.val()}
			if (page.parent && page.parent != parent) {
				/* Page's parent has changed - remove as child from current parent */
				firebase.database().ref('pages/'+page.parent).child('children').child(id).remove();
			}
		});
		
		return firebase.database().ref('pages').child(id).update({
			title: title,
			parent: parent,
			style: style,
			body: body
		}).then((resp) => {
			
			if (!parent) { return; }
			
			return firebase.database().ref('pages/'+parent).child('children').child(id).set(true);
		});
	},
	
	/* Delete Page */
	
	deletePage: (id) => {
		
		/* Check current state of page */
		firebase.database().ref('pages/'+id).on('value', resp => {
			const page = {...resp.val()}
			if (page.parent) {
				/* Remove reference to this page from parent page */
				firebase.database().ref('pages/'+page.parent).child('children').child(id).remove();
			}
			if (page.children) {
				for (let key in page.children) {
					firebase.database().ref('pages').child(key).remove();
				}
			}
		});
		
		return firebase.database().ref('pages').child(id).remove();
	},
	
	/* Reorder Pages */
	
	reorderPages: (order, parent) => {
		
		order.map((page, index) => {
			firebase.database().ref('pages').child(page).update({
				order: index
			});
		})
	}
	
}



