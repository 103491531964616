import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import contactActions from './../../actions/contacts';
import { protectedPages } from './../../consts';

export class ContactGroupEntry extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			title: null,
			description: null
		}
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		if (match.params.id) {
			
			this.getGroup(match.params.id);
		}
	}
	
	getGroup(id) {
		
		contactActions.getGroup(id).on('value', resp => {
			
			const group = resp.val();
			
			if (group == null) { return; }
			
			const { title, description } = group;
			
			this.setState({
				id: id,
				title,
				description
			})
		});
	}
	
	save() {
		
		const { history } = this.props;
		const { id, title, description } = this.state;
		
		if (id) {
			
			contactActions.saveGroup(id, title, description).then((resp) => {
				history.push('/contacts');
			})
			
		} else {
		
			contactActions.createGroup(title, description).then((resp) => {
				history.push('/contacts');
			})
		}
	}
	
	cancel() {
		
		const { history } = this.props;
		history.push('/contacts');
	}
	
	delete() {
		
		const { id, group } = this.state;
		
		if (window.confirm("Are you sure you want to delete this group of contacts?")) {
			contactActions.deleteGroup(id).then((resp) => {
				const { history } = this.props;
				history.push('/contacts');
			})
		}
	}
	
	render() {
		
		const { id, title, description, pages, parent, body, editorState } = this.state;
		
		return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } Group</h1>
				</div>
				<div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field">
						<label className="input-label">Group Title</label>
						<input 
							type="text" 
							name="title" 
							placeholder="Enter Title"
							value={title} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ title: event.target.value })
							}} />
					</div>
							
					<div className="form-field">
						<label className="input-label">Group Description</label>
						<input 
							type="text" 
							name="description" 
							placeholder="Enter Description"
							value={description} 
							className="text-input text-input-block"
							onChange={(event) => {
								this.setState({ description: event.target.value })
							}} />
					</div>
							
					<div className="button-bar">
						<button 
							className="button button-primary" 
							onClick={() => { this.save() }}>Save</button>
						<button 
							className="button button-primary button-outline" 
							onClick={() => { this.cancel() }}>Cancel</button>
						{ (id && protectedPages.indexOf(id) == -1) && <button 
							className="button button-danger button-align-end" 
							onClick={() => { this.delete() }}>Delete</button> }
					</div>
				</div>
				
			</div>
		);
	}
}
