import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import pageActions from './../../actions/pages';
import { EditorState, convertToRaw, ContentState, Modifier, AtomicBlockUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import { protectedPages, noContentPages } from './../../consts';
/* TEMP STYLES */
import './../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export class PageEntry extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null, 
			title: '',
			parent: null,
			style: 'primary',
			body: null,
			pages: [],
			editorState: EditorState.createEmpty(),
            distanceFromTop: 0
		}
        this.toolbar = null;
	}
	
	componentDidMount() {
		
		const { match } = this.props;
		
		pageActions.getPages().on('value', resp => {
			
			const pages = resp.val();
			const pagesArray = Object.keys(pages).map((key) => { return { id: key, ...pages[key] } });
			
			this.setState({
				pages: pagesArray
			})
		});
		
		if (match.params.id) { 
		
			pageActions.getPage(match.params.id).on('value', resp => {

				let { title, parent = null, style = 'primary', body = null } = {...resp.val()}

				this.setState({
					id: match.params.id,
					title: title,
					parent: parent,
					style: style,
					body: body
				})

				if (body) {

					if (!body.startsWith('<p')) { body = '<p></p>' + body }
					
					const contentBlock = htmlToDraft(body);
					const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
					const editorState = EditorState.createWithContent(contentState);
					
					this.setState({
						editorState
					});
				}
			});
		}
		else if (match.params.parent) { 
			
			this.setState({
				parent: match.params.parent
			})
		}
      
        /* Scroll Event */
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.toolbar = document.getElementsByClassName('rdw-editor-toolbar')[0];
        this.setState({
            distanceFromTop: this.toolbar.getBoundingClientRect().top
        })
	}
  
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
	
    handleScroll() {
      
        const { distanceFromTop } = this.state;
        const transform = window.scrollY > distanceFromTop ? window.scrollY - distanceFromTop - 1 : 0;
        
        this.toolbar.style.transform = "translateY("+transform+"px)";
    }
  
	save() {
		
		const { history } = this.props;
		const { id, title, parent, style, body } = this.state;
		
		const action = id ? 
			pageActions.savePage(id, title, parent, style, body) : 
			pageActions.createPage(title, parent, style, body)
		
		action.then((resp) => {
			if (parent) {
				history.push('/pages/entry/' + parent);
			} else {
				history.push('/pages');
			}
		})
		
	}
	
	cancel() {
		
		const { history } = this.props;
		const { parent } = this.state;
		
		if (parent) {
			history.push('/pages/entry/' + parent);
		} else {
			history.push('/pages');
		}
	}
	
	delete() {
		
		const { match } = this.props;
		
		if (window.confirm("Are you sure you want to delete this page and it's sub pages?")) {
			pageActions.deletePage(match.params.id).then((resp) => {
				const { history } = this.props;
				history.push('/pages');
			})
		}
	}
	
	_renderPagesList() {
		
		const allPages = this.state.pages;
		
		let level = 0;
		let pagesArray = [];
        let root = null;
		
		const loop = (pages, parent) => {
            
			pages.filter(p => p.parent == parent).map((page) => {
				
                root = !parent ? page.title : root;
                
				const { id, title, children = null } = page;
			
				if (id == this.state.id) {
					return false;
				}
				
				level = 0;
				
				/* Get 'level' of parent page and add 1 */
				if (page.parent) {
					for (let i = 0; i < pagesArray.length; i++) {
						if (pagesArray[i].id == page.parent) {
							level = pagesArray[i].level + 1;
						}
					}
				}
				
				const childrenArray = children ? Object.keys(children) : [];
                
				let childPages = allPages.filter(p => childrenArray.indexOf(p.id) > -1);
					childPages.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
				
				pagesArray.push({ level, id, title, children })
				
				if (childPages.length > 0) {
					loop(childPages, id)
				}
			})
		}
		
		const { pages } = this.state;

		loop(pages, null)
		
		return (
			pagesArray.map((page) => {
				return (
					<option key={page.id} value={page.id}>{ ('\u00A0 \u00A0 \u00A0').repeat(page.level) }{ page.title }</option>
				)
			})
		)
	}
	
	render() {
		
		const { id, pages, parent, style, body, editorState } = this.state;
		
		const toolbar = {
			options: ['inline', 'list', 'link', 'history'], 
			inline: {
				options: ['bold']
			},
			list: {
				options: ['unordered', 'ordered']
			}
		}
		
		return (
			<div>
				<div className="title">
					<h1>{ id ? 'Edit' : 'New' } Page</h1>
				</div>
				<div className="form" style={{ marginBottom: '16px' }}>
			
					<div className="form-field-group">
						<div className="form-field">
							<label className="input-label">Page Title</label>
							<input 
								type="text" 
								name="title" 
								placeholder="Enter Title"
								value={this.state.title} 
								className="text-input text-input-block"
								onChange={(event) => {
									this.setState({ title: event.target.value })
								}} />
						</div>
						<div className="form-field">
							<label className="input-label">Parent Page (optional)</label>
							<select
								value={ parent }
								className="select-box select-box-block"
								onChange={(event) => {
									this.setState({ parent: event.target.value })
								}}>
								<option value={'none'}>Select Parent (none)</option>
								<option value={'none'} disabled>------</option>
								{ this._renderPagesList() }
							</select>
						</div>
					</div>
							
					{ (!parent || parent == 'none') && <div className="form-field">
						<label className="input-label">Page Style</label>
						<label className="input-label input-label-note">Primary pages are your main areas of content. Secondary pages are for content such as about, privacy policy, etc.</label>
						<select
							value={ style }
							className="select-box select-box-block"
							onChange={(event) => {
								this.setState({ style: event.target.value })
							}}>
							<option value={'primary'}>Primary</option>
							<option value={'secondary'}>Secondary</option>
						</select>
					</div> }
					
                    { (!id || noContentPages.indexOf(id) == -1) && <div className="form-field">
						<label className="input-label">Page Content</label>
						<Editor 
							toolbar={toolbar}
							toolbarCustomButtons={[<CustomOption />]}
							editorState={editorState}
							onEditorStateChange={(editorState) => {

								const contentToHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();

								this.setState({
									body: contentToHTML,
									editorState
								});
							}} />
					</div> }
							
					<div className="button-bar">
						<button 
							className="button button-primary" 
							onClick={() => { this.save() }}>Save</button>
						<button 
							className="button button-primary button-outline" 
							onClick={() => { this.cancel() }}>Cancel</button>
						{ (id && protectedPages.indexOf(id) == -1) && <button 
							className="button button-danger button-align-end" 
							onClick={() => { this.delete() }}>Delete</button> }
					</div>
				</div>
				
			</div>
		);
	}
}

/* Custom Toolbar Options */
											  
class CustomOption extends Component {

	constructor(props) {
		super(props);
		this.storageRef = firebase.storage();
	}

	addImage = (filename) => {
											  
		this.storageRef.ref("images").child(filename).getDownloadURL().then((url) => {
			
			const getImageDimensions = (url) => {
				return new Promise((resolve, reject) => {
					let img = new Image();
					img.addEventListener('load', (resp) => {
						resolve(resp);
					});
					img.src = url;							  
				})
			}
			
			getImageDimensions(url).then((resp) => {
											  
				const { width, height } = resp.path[0];
				const { editorState, onChange } = this.props;
				const entityData = { src: url, height: height, width: width }
				const entityKey = editorState.getCurrentContent().createEntity('IMAGE', 'MUTABLE', entityData).getLastCreatedEntityKey();
				const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

				onChange(newEditorState);	
			})
		});								  
	}
	
	addVideo = (event) => {
		
		const url = "https://changinglivesapp.com/api/upload-video.php";
		
		const formData = new FormData();
			formData.append('files[]', event.target.files[0]);
		
		fetch(url, {
			method: 'POST',
			body: formData
		}).then((resp) => {
			resp.json().then((responseData) => {
				
				const url = responseData.url;
				console.log(url)
				const { editorState, onChange } = this.props;								  
				const entityData = { src: url }
				const entityKey = editorState.getCurrentContent().createEntity('EMBEDDED_LINK', 'MUTABLE', entityData).getLastCreatedEntityKey();
				const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

				onChange(newEditorState);
			});
		}, error => {
			// Error
		});
	}

	render() {

		return (
			<div className="rdw-history-wrapper">
				{/* Image Upload */}
				<label htmlFor="image-upload" className="rdw-option-wrapper rdw-option-image" title="Image">
					<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMzJweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSIzMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNMzAsMkgyQzAuOSwyLDAsMi45LDAsNHYyNGMwLDEuMSwwLjksMiwyLDJoMjhjMS4xLDAsMi0wLjksMi0yVjRDMzIsMi45LDMxLjEsMiwzMCwyeiBNMjgsMjZIMTQuOTc5bDUuNzAyLTcuNjM5TDI4LDIwLjg2ICBWMjZ6IE0yOCwxNi42MzVsLTYtMi4wNUMyMSwxNC4yMzQsMjAuNDg0LDE0LDIwLDE0Yy0wLjcwMywwLTEuMjUsMC4yODEtMiwxLjI2Nkw5Ljk4NiwyNkg0VjZoMjRWMTYuNjM1eiBNMTAsMTYgIGMyLjIwOSwwLDQtMS43OTEsNC00cy0xLjc5MS00LTQtNHMtNCwxLjc5MS00LDRTNy43OTEsMTYsMTAsMTZ6Ii8+PC9zdmc+" />
					<FileUploader
						accept="image/*"
						name="image"
						id="image-upload"
						randomizeFilename
						storageRef={this.storageRef.ref("images")}
						onUploadSuccess={(filename) => {
							this.addImage(filename)
						}} />
				</label>
				{/* Video Upload */}
				<label htmlFor="video-upload" className="rdw-option-wrapper rdw-option-video" title="Video">
					<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxOHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxOCIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTQuMDAwMDAwLCAtNDY1LjAwMDAwMCkiPjxnIGlkPSJ0aGVhdGVycyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjE0LjAwMDAwMCwgNDY1LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNCwwIEwxNCwyIEwxMiwyIEwxMiwwIEw0LDAgTDQsMiBMMiwyIEwyLDAgTDAsMCBMMCwxOCBMMiwxOCBMMiwxNiBMNCwxNiBMNCwxOCBMMTIsMTggTDEyLDE2IEwxNCwxNiBMMTQsMTggTDE2LDE4IEwxNiwwIEwxNCwwIEwxNCwwIFogTTQsMTQgTDIsMTQgTDIsMTIgTDQsMTIgTDQsMTQgTDQsMTQgWiBNNCwxMCBMMiwxMCBMMiw4IEw0LDggTDQsMTAgTDQsMTAgWiBNNCw2IEwyLDYgTDIsNCBMNCw0IEw0LDYgTDQsNiBaIE0xNCwxNCBMMTIsMTQgTDEyLDEyIEwxNCwxMiBMMTQsMTQgTDE0LDE0IFogTTE0LDEwIEwxMiwxMCBMMTIsOCBMMTQsOCBMMTQsMTAgTDE0LDEwIFogTTE0LDYgTDEyLDYgTDEyLDQgTDE0LDQgTDE0LDYgTDE0LDYgWiIgaWQ9IlNoYXBlIi8+PC9nPjwvZz48L2c+PC9zdmc+" />
					<input 
						type="file"
						accept="video/mp4"
						name="video"
						id="video-upload"
						onChange={(event) => { 
							this.addVideo(event)
						}} />
				</label>
			</div>
		)
	}
}

const styles = {
	editor: {
		border: '1px solid gray'
	}
};